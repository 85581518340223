import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2 from 'components/typography/h2'
import { graphql, Link } from 'gatsby'
import Button from 'components/button/button'
import { Helmet } from 'react-helmet'
import YouTube from 'components/youtube'
import { FaCogs } from '@react-icons/all-files/fa/FaCogs'
import { FaWrench } from '@react-icons/all-files/fa/FaWrench'
import { FaHistory } from '@react-icons/all-files/fa/FaHistory'
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'
import Layout from 'components/layout'
import Content from 'components/content'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const videos = [
  {
    title: 'How to Create an Account',
    link: '/hutson-customer-portal/creating-an-account/',
  },
  {
    title: 'How to View Past Invoices',
    link: '/hutson-customer-portal/viewing-past-invoices/',
  },
  {
    title: 'How to Order Parts',
    link: '/hutson-customer-portal/ordering-parts/',
  },
]

const features = [
  {
    title: 'Remember JDParts?',
    icon: <FaCogs aria-hidden='true' focusable='false' role='presentation' />,
    description:
      'The Hutson Customer Portal is even easier to use to order parts and keeps an order history that is easy to see.',
  },
  {
    title: 'Request Service',
    icon: <FaWrench aria-hidden='true' focusable='false' role='presentation' />,
    description:
      'Make a request for service on your own time 24/7. Hutson will give you a call during business hours and work out the rest of the details. ',
  },
  {
    title: 'View Transaction History',
    icon: <FaHistory aria-hidden='true' focusable='false' role='presentation' />,
    description:
      'View, search and sort your full transaction history for all of your accounts. One login from any device.',
  },
  {
    title: 'Browse Equipment',
    icon: <FaSearch aria-hidden='true' focusable='false' role='presentation' />,
    description:
      'See your entire fleet in our system. Request to add, update, or delete units that need it. You will also be able to browse our used equipment selection.',
  },
]

const HutsonCustomerPortalPage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Hutson Customer Portal | Hutson Inc</title>
        <meta
          name='description'
          content='Imagine being able to see past transactions, easy parts ordering, and being able to check our parts inventory without driving to the dealership. Now you can with the Hutson Customer Portal at Hutson.'
        />
        <meta
          name='keywords'
          content='Hutson Customer Portal, john deere, parts, service, transaction history, equipment, jdparts'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'VideoObject',
            'name': 'Hutson Hutson Customer Portal Teaser',
            'description':
              "Do more with a click. See what the hype is all about and how we're making it possible to spend time on your farm where you're making money. Visit our website to create an account or learn more about the Hutson Customer Portal.",
            'thumbnailUrl': 'https://i.ytimg.com/vi/ZY9ax513xVo/hqdefault.jpg',
            'uploadDate': '2018-09-21T15:03:32+00:00',
            'duration': 'PT32S',
            'embedUrl': 'https://youtube.googleapis.com/v/ZY9ax513xVo',
          })}
        </script>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Hutson Customer Portal',
                'item': 'https://www.hutsoninc.com/hutson-customer-portal/',
              },
            ],
          })}
        </script>
      </Helmet>

      <StyledHeroImage title='Hutson Customer Portal' image={heroImage}>
        <div>
          <Button as={HeroButton} href='https://hutson.dealercustomerportal.com/Login'>
            Login
          </Button>
          <Button as={HeroButton} href='https://hutson.dealercustomerportal.com/Sign-Up' ghost>
            Sign Up
          </Button>
        </div>
        <GuestOrderLink href='https://hutson.dealercustomerportal.com/Customers/Specials#!/'>
          Order Parts as Guest
        </GuestOrderLink>
      </StyledHeroImage>

      <Content>
        <H2>Introducing the Hutson Customer Portal</H2>
        <p>
          Imagine being able to see past transactions, easy parts ordering, and being able to check
          our parts inventory without driving to the dealership. Now you can with the Hutson
          Customer Portal at Hutson.
        </p>
        <Button as={StyledA} href='https://hutson.dealercustomerportal.com/Sign-Up'>
          Sign Up
        </Button>
        <Section>
          <YouTube videoId='ZY9ax513xVo' />
        </Section>
      </Content>

      <FeaturesContainer>
        <Wrapper>
          <FeaturesList>
            {features.map((feature, key) => (
              <FeaturesListItem key={key}>
                <div className='feature-icon'>{feature.icon}</div>
                <h3 className='feature-title'>{feature.title}</h3>
                <hr />
                <p className='feature-description'>{feature.description}</p>
              </FeaturesListItem>
            ))}
          </FeaturesList>
        </Wrapper>
      </FeaturesContainer>

      <Content>
        <H2>Tutorials</H2>
        <VideosList>
          {videos.map((video, key) => (
            <VideoListItem key={key}>
              <VideoTitle to={video.link}>
                {key + 1}.&nbsp;&nbsp;
                {video.title}
              </VideoTitle>
              <Button as={VideoButton} to={video.link}>
                Watch Tutorial
              </Button>
            </VideoListItem>
          ))}
        </VideosList>
      </Content>
    </Layout>
  )
}

const StyledHeroImage = styled(HeroImage)`
  height: 400px !important;

  @media (min-width: 600px) {
    height: 350px !important;
  }
`

const Section = styled.div`
  margin: 40px 0 0;

  @media (min-width: 800px) {
    margin: 80px 0 0;
  }
`

const Wrapper = styled(Content)`
  padding: 0;
`

const StyledA = styled(OutboundLink)`
  display: block;
  margin: 0;
  text-decoration: none;
  width: 200px;
`

const HeroButton = styled(OutboundLink)`
  display: inline-block;
  margin-top: 10px;
  margin-right: 15px;
  text-decoration: none;
  width: 200px;
`

const GuestOrderLink = styled(OutboundLink)`
  color: #fff;
  display: inline-block;
  font-style: italic;
  margin-top: 15px;
  opacity: 0.8;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
    opacity: 1;
  }
`

const FeaturesContainer = styled.div`
  background-color: #efefef;
  background: url('/customer-portal-features-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;

  ${Wrapper} {
    padding: 40px 0;
  }
`

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;

  @media (min-width: 800px) {
    ${clearfix}
  }
`

const FeaturesListItem = styled.li`
  text-align: center;

  .feature-icon {
    height: 50px;
    font-size: 1.75em;
  }

  h3 {
    font-weight: bold;
    margin: 10px auto 0;
  }

  hr {
    border: none;
    height: 2px;
    color: #387c2c;
    background-color: #387c2c;
    width: 150px;
    margin: 6px auto;
  }

  @media (min-width: 800px) {
    ${column('1/2')}
  }

  @media (min-width: 1366px) {
    ${column('1/4')}
  }
`

const VideosList = styled.ul`
  list-style: none;
  margin: 20px auto 0;
  padding: 0;
`

const VideoListItem = styled.li`
  ${clearfix}
  background-color: #fafafa;
  border: 1px solid #bbbbbb;
  margin-bottom: 10px;
  padding: 25px;
  transition: all 0.2s ease;

  :hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 800px) {
    padding: 10px 20px;
  }
`

const VideoTitle = styled(Link)`
  color: #000;
  display: block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1.2;
  padding: 0;
  text-decoration: none;

  @media (min-width: 800px) {
    float: left;
    font-size: 0.9em;
    line-height: 1;
    padding: 18px 0;
  }
`

const VideoButton = styled(Link)`
  display: block;
  margin-top: 20px;
  text-decoration: none;
  width: 200px;

  @media (min-width: 800px) {
    margin-top: 0;
    float: right;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "customer-portal-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default HutsonCustomerPortalPage
